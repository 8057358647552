<!--  -->
<template>
  <div>
    <blockTitle title="存储容器">
      <template #customContent>
        <div class="text-red-500 text-xs ml-4">
          删除存储容器将会清空容器内的所有文件，请谨慎操作！
        </div>
      </template>
    </blockTitle>
    <el-card shadow="never">
      <div class="card-content">
        <AppTable
            ref="AppTable"
            controller="sysContainer"
            :tableColumns="tableColumns"
            :height="tableHeight"
        >
          <template #left-operator>
            <el-button @click="createBucket" size="small" type="primary"
            >创建存储容器
            </el-button>
          </template>
          <template #cloudType="{ row }">
            {{ row.cloudFactoryName }}-{{ row.cloudFactoryProductName }}
          </template>
          <template #periodType="{ row }">
            {{ filterPeriodType(row.periodType) }}
          </template>
          <template #containerSize="{ row }">
            <template v-if="row.totalContainerSize === 0">
              <span>不限制</span>
            </template>
            <template v-if="row.totalContainerSize > 0">
              {{ row.useContainerSize }}GB / {{ row.totalContainerSize }}GB
              <el-progress
                  :percentage="row.useContainerSize / row.totalContainerSize"
                  :show-text="false"
              ></el-progress>
            </template>
          </template>
          <template #operator="{ row }">
            <el-button @click="deleteRow(row)" size="mini" type="danger"
            >删除
            </el-button>
          </template>
          <template #useState="{ row }">
            <div
                class="w-16 h-6 flex align-items-center justify-content-center text-xs text-green-500 bg-green-100 pt-0.5 pb-0.5 pl-2 pr-2 border border-green-200 rounded"
                v-if="row.useState === 2"
            >
              已使用
            </div>
            <div
                class="w-16 h-6 flex align-items-center justify-content-center text-xs text-gray-500 bg-gray-100 pt-0.5 pb-0.5 pl-2 pr-2 border border-gray-200 rounded"
                v-if="row.useState === 1"
            >
              未使用
            </div>
          </template>
        </AppTable>
      </div>
      <addBucketDialog
          @on-add="refreshTable"
          ref="addBucketDialog"
      ></addBucketDialog>
    </el-card>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less">
.card-content {
  height: calc(100vh - 180px);
}
</style>
<script type="text/ecmascript-6">
import addBucketDialog from '@/components/bucket/addBucketDialogV2'

export default {
  data () {
    return {
      tableColumns: [
        {
          label: '容器名称',
          prop: 'containerName'
        },
        {
          label: 'BucketName',
          prop: 'bucketName'
        },
        {
          label: '存储产品',
          type: 'customSlot',
          slotName: 'cloudType'
        },
        {
          label: '密钥名称',
          prop: 'sysSecretKeyName'
        },
        {
          label: '地区',
          prop: 'sysSecretKeyName'
        },
        {
          label: '存储空间（GB）',
          type: 'customSlot',
          slotName: 'containerSize'
        },
        {
          label: '生命周期',
          type: 'customSlot',
          slotName: 'periodType'
        },
        {
          label: '使用状态',
          type: 'customSlot',
          slotName: 'useState'
        },
        {
          label: '操作',
          type: 'customSlot',
          slotName: 'operator'
        }
      ],
      tableHeight: 500
    }
  },
  components: {
    addBucketDialog
  },
  methods: {
    filterPeriodType (periodType) {
      switch (periodType) {
        case 1:
          return '永久'
        case 2:
          return '30天'
        case 3:
          return '180天'
        case 4:
          return '1年'
        default:
          return '未知'
      }
    },
    refreshTable () {
      this.$refs.AppTable.refresh()
    },
    deleteRow (row) {
      this.$api.delete('sysContainer', row.id).then(res => {
        this.refreshTable()
      })
    },
    createBucket () {
      this.$refs.addBucketDialog.show()
    }
  },
  mounted () {
  }
}
</script>
